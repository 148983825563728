body {
    letter-spacing: rem(.3px);
}

p:last-child {
    margin-bottom: 0;
}

pre[class*="language-"], code[class*="language-"] {
    text-shadow: none;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }
}

.hide-scrollbar {
    @include hide-scrollbar();
}

[data-horizontal-scroll] {
    overflow-x: scroll;
}

.stretched-label {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
}

.z-index-max {
    z-index: $zindex-modal - 10;
}

.card-columns {
    column-count: 1;

    @include media-breakpoint-only(sm) {
        column-count: 1;
    }

    @include media-breakpoint-only(md) {
        column-count: 1;
    }

    @include media-breakpoint-only(lg) {
        column-count: 1;
    }

    @include media-breakpoint-only(xl) {
        column-count: 2;
    }

    @include media-breakpoint-only(xxl) {
        column-count: 2;
    }
}