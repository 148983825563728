//
// Avatar
//

.avatar {
    background: theme-color("light");
    display: inline-block;
    position: relative;
    line-height: 0;

    &,
    & > img {
        border-radius: 50%;
    }

    & > i,
    & > span {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
}

//
// Avatar Sizes
//

.avatar,
.avatar > img {
    @include theme_size(rem(50px));

    & > i,
    & > span {
        font-size: $font-size-base;
    }
}

.avatar-xs,
.avatar-xs > img {
    @include theme_size(rem(20px));

    & > i,
    & > span {
        font-size: rem(8px);
    }
}

.avatar-sm,
.avatar-sm > img {
    @include theme_size(rem(44px));

    & > i,
    & > span {
        font-size: rem(12px);
    }
}

.avatar-lg,
.avatar-lg > img {
    @include theme_size(rem(64px));

    & > i,
    & > span {
        font-size: rem(18px);
    }
}

.avatar-xl,
.avatar-xl > img {
    @include theme_size(rem(92px));

    & > i,
    & > span {
        font-size: rem(20px);
    }
}

//
// Avatar State
//

.avatar {

    &.avatar-online::before,
    &.avatar-offline::before {
        position: absolute;
        border: 3px solid $body-bg;
        display: block;
        bottom: -3px;
        right: 0px;
        content: "";
        border-radius: 50%;
        transform: translate(-10%,0%);
        @include theme_size(30%);
    }

    &.avatar-online::before {
        background: theme-color("success");
    }

    &.avatar-offline::before {
        background: theme-color("secondary");
    }
}