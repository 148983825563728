//
// Badge
//

.badge-circle {
    position: relative;
    border-radius: 50%;
    padding: 0;
    vertical-align: middle;
    height: ($badge-padding-y * 2) + rem(floor(strip-unit(16) /100 * floor(strip-unit($badge-font-size))));
    width: ($badge-padding-y * 2) + rem(floor(strip-unit(16) /100 * floor(strip-unit($badge-font-size))));
    min-width: ($badge-padding-y * 2) + rem(floor(strip-unit(16) /100 * floor(strip-unit($badge-font-size))));

    > i,
    > span {
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
    }
}

//
// Badge(absolute)
//

.badge-top-left,
.badge-top-right,
.badge-top-center,
.badge-bottom-left,
.badge-bottom-right,
.badge-bottom-center {
    position: absolute;
    z-index: 1;
}

.badge-top-left {
    top: 0;
    left: 0;
    transform: translate(-25%, -25%);
}

.badge-top-right {
    top: 0;
    right: 0;
    transform: translate(25%, -25%);
}

.badge-top-center {
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
}

.badge-bottom-left {
    left: 0;
    bottom: 0;
    transform: translate(-25%, 25%);
}

.badge-bottom-right {
    right: 0;
    bottom: 0;
    transform: translate(25%, 25%);
}

.badge-bottom-center {
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 50%);
}

//
// Badge Border
//

@each $color, $value in $theme-colors {
    .badge-border-#{$color} {
        border: 5px solid $value;
   }
}

//
// Badge Dot
//

.badge-dot {
    font-size: 0;
    vertical-align: middle;
    padding: 0;
    border-radius: 50%;
    line-height: rem(6px);
    @include theme_size(rem(6px));

    &:empty {
        display: inline-block;
    }
}

//
// Badge small
//

.badge-sm {
    padding: 0.3625rem 0.45rem;
    font-size: 62.667%;
}