@font-face {
    font-family: 'Roboto';
    src: url('../../assets/fonts/roboto/roboto.italic-webfont.woff2') format('woff2'),
         url('../../assets/fonts/roboto/roboto.italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../assets/fonts/roboto/roboto.bold-webfont.woff2') format('woff2'),
         url('../../assets/fonts/roboto/roboto.bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../assets/fonts/roboto/roboto.regular-webfont.woff2') format('woff2'),
         url('../../assets/fonts/roboto/roboto.regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../assets/fonts/roboto/roboto.medium-webfont.woff2') format('woff2'),
         url('../../assets/fonts/roboto/roboto.medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

a {
    transition: all .25s ease-in-out;

    &:hover,
    &:focus {
        text-decoration: underline;
        text-decoration: none;
        outline: 0;

        &.text-muted {
            color: darken($text-muted, 20%) !important;
        }
    }

    &.text-muted.active {
        color: darken($text-muted, 20%) !important;
    }
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {

    > a.text-reset:hover {
        color: $link-color !important;
    }
}

h1, .h1 {
    margin-bottom: rem(8px);
}

h2, .h2 {
    margin-bottom: rem(8px);
}

h3, .h3 {
    margin-bottom: rem(8px);
}

h4, .h4 {
    margin-bottom: rem(6px);
    letter-spacing: rem(.6px);
}

h5, .h5 {
    margin-bottom: rem(4px);
    letter-spacing: rem(.6px);
}

h6, .h6 {
    margin-bottom: rem(2px);
}

//
// Font Weight
//

.font-light {
    font-weight: $font-weight-light !important;
}

.font-normal {
    font-weight: $font-weight-normal !important;
}

.font-medium {
    font-weight: $font-weight-medium !important;
}

.font-bold {
    font-weight: $font-weight-bold !important;
}

//
// Small
//

.extra-small {
    font-size: $small-extra-font-size;
}

//
// Typing Animation
//

.typing-dots span {
    opacity: 0;

    &:nth-child(1) {
        animation: 1s type-animation infinite .33333s;
    }
    &:nth-child(2) {
        animation: 1s type-animation infinite .66666s;
    }
    &:nth-child(3) {
        animation: 1s type-animation infinite .99999s;
    }
}

@keyframes type-animation {

    50% {
        opacity: 1
    }

}