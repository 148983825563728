.emoji-picker {
    background: $body-bg;
    border: 1px solid $body-bg;
    border-radius: $border-radius;
    box-shadow: $dropdown-box-shadow;
    width: 320px;
    opacity: 0;
    transition: opacity 0.3s;
    margin: 0 0.5em 1rem;
    overflow: hidden;
}

.emoji-picker.visible {
    opacity: 1;
}

.emoji-picker__content {
    height: 20rem;
    overflow: hidden;
    position: relative;
}

.emoji-picker__preview {
    height: (rem(12px) * 2) + rem(32px);
    padding: rem(12px);
    border-top: 1px solid $border-color;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.emoji-picker__preview-emoji {
    font-size: rem(32px);
    margin-right: 0.25em;
    font-family: "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "EmojiOne Color", "Android Emoji";
}

.emoji-picker__preview-name {
    font-size: 0.85em;
    overflow-wrap: break-word;
    word-break: break-all;
}

.emoji-picker__tabs-container {
    border-bottom: 1px solid $border-color;
}

.emoji-picker__tabs {
    margin: 0;
    padding: 0;
    display: flex;
    padding: 0 rem(12px);
}

.emoji-picker__tab {
    font-size: 1.5rem;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-grow: 1;
    text-align: center;
    border-radius: 3px;
    transition: background 0.25s;
    position: relative;

    &::after {
        content: "";
        width: 100%;
        background: transparent;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    > svg {
        padding: 0.5rem;
        width: 100%;
        height: 34px;
    }
}

.emoji-picker__tab:hover,
.emoji-picker__tab.active {

    &::after {
        background: theme-color("primary");
    }
}

.emoji-picker__tab-body {
    margin-top: 0.5em;
    transform: translateX(25rem);
    transition: transform 0.25s;
    position: absolute;
    overflow-y: scroll;
    height: calc(100% - 42px);
    @include hide-scrollbar;
    width: 100%;
}

.emoji-picker__tab-body h2 {
    font-size: 0.85rem;
    margin: 0;
    text-align: left;
    padding: rem(12px);
    border-bottom: 1px solid $border-color;
}

.emoji-picker__tab-body.active {
    display: block;
    transform: translateX(0);
}

.emoji-picker__emojis {

    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: auto;
    width: 100%;
    margin: auto;
    padding: rem(12px);
}

.emoji-picker__emojis.search-results {
    height: 21rem;
}

.emoji-picker__emoji {
    background: transparent;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: rem(24px);
    width: 11.11%;
    padding: 0;
    margin: 0;
    outline: none;
    font-family: "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "EmojiOne Color", "Android Emoji";
}

.emoji-picker__emoji:focus, .emoji-picker__emoji:hover {
    background: theme-color("light");
}

.emoji-picker__search-container {
    padding: rem(12px);
    position: relative;
    height: auto !important;
    display: flex;
    border-bottom: 1px solid $border-color;
}

.emoji-picker__search-icon {
    display: none;
}

.emoji-picker__search-not-found {
    text-align: center;
    margin-top: 2em;
}

.emoji-picker__search-not-found-icon {
    font-size: 3em;

    > svg {
        width: 40px;
        height: 40px;

    }
}

.emoji-picker__search-not-found h2 {
    margin: 0.5em 0;
    font-size: 1em;
}

.emoji-picker__variant-overlay {
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 23rem;
    width: 100%;
    height: 27.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.emoji-picker__variant-popup {
    background: $body-bg;
    border-radius: $border-radius;
    margin: 0.5em;
    padding: 0.5em;
    text-align: center;
}

.emoji-picker__variant-popup-close-button {
    cursor: pointer;
    background: transparent;
    border: none;
    position: absolute;
    right: 1em;
    padding: 0;
    top: calc(50% - 0.5em);
    height: 1em;
    width: 1em;

    > svg {
        width: 9px;
        display: block;
        color: $basic-inverse;
    }
}